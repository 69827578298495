import { useMemo } from 'react';
import { HeaderBanner } from 'components/atoms';
import { TalentBank, GallerySection } from 'components/molecules';
import { BoxCompanyAboutWe, SearchBar, ListVacancies } from 'components/organisms';
import useStore from 'store';
import { useRouter } from 'next/router';
import { QueryVacancy } from 'types/vacancy';
import { getParamsSearchVacancy } from 'utils/format';
import { Feedbacks } from 'components/molecules/Feedbacks';
import { Box, Text } from '@solides/management-util-ui';
import { useApplyTalentBank } from 'hooks/useApplyTalentBank';
import { useVacancies } from 'queries/vacancy';

function Home(): JSX.Element {
  const router = useRouter();
  const { company } = useStore(state => state.company);
  const { hasApplied, onApply } = useApplyTalentBank();
  const filteredPhotos = company?.photos?.filter(photo => photo !== null);
  const testimonials = company?.testimonials;

  const params = getParamsSearchVacancy(router.query as QueryVacancy);

  if (typeof window !== 'undefined') {
    params.take = window.innerWidth < 767 ? 6 : 8;
  }
  params.slug = company?.slug;

  const searchParams = {
    title: params.title,
    locations: params.locations,
    take: params.take.toString(),
    page: params.page.toString(),
    slug: params.slug,
  };

  const { data: response, isLoading: isLoadingListVacancies } = useVacancies(searchParams, !!params.slug);

  const vacancies = useMemo(() => response?.data?.data?.data || [], [response?.data?.data?.data]);

  const pagination = {
    totalPages: response?.data?.data?.totalPages || 1,
    total: response?.data?.data?.count || 0,
    current: params?.page || 1,
    take: params?.take || 10,
    pathname: '/',
  };

  const emptyStates = useMemo(() => {
    if (params?.title || params?.locations) {
      return (
        <span>
          {company?.name} não tem vagas com as especificações desejadas.{' '}
          {!hasApplied && (
            <>
              Verifique os filtros aplicados ou{' '}
              <a
                href="#"
                className="space-x-2 items-center text-[16px] border rounded-lg hover:rounded-lg hover:shadow-none disabled:shadow-none disabled:text-state-disabled/[0.8] hover:disabled:text-state-disabled/[0.8] transition duration-300 ease-in-out border-transparent hover:disabled:bg-transparent text-primary hover:bg-primary-500/[0.08] text-button-md max-h-9 w-[200px] underline p-1 font-normal"
                onClick={onApply}
              >
                se cadastre no banco de talentos
              </a>
            </>
          )}
        </span>
      );
    }

    if (hasApplied)
      return (
        <span>
          {company?.name} não tem vagas disponibilizadas no momento e você já está cadastrado no banco de
          talentos. Para visualizar os bancos de talentos que você se cadastrou.
          <br />
          <a
            href={`${process.env.CANDIDATE_HOST}/#company`}
            className="space-x-2 items-center border rounded-lg hover:rounded-lg hover:shadow-none disabled:shadow-none disabled:text-state-disabled/[0.8] hover:disabled:text-state-disabled/[0.8] transition duration-300 ease-in-out border-transparent hover:disabled:bg-transparent text-primary hover:bg-primary-500/[0.08] text-button-md max-h-9 w-[200px] text-[16px] underline p-1 font-normal"
          >
            Clique aqui
          </a>
        </span>
      );

    return (
      <span>
        {company?.name} não tem vagas disponibilizadas no momento. Para não perder uma oportunidade,{' '}
        <a
          href="#"
          className="space-x-2 items-center border rounded-lg hover:rounded-lg hover:shadow-none disabled:shadow-none disabled:text-state-disabled/[0.8] hover:disabled:text-state-disabled/[0.8] transition duration-300 ease-in-out border-transparent hover:disabled:bg-transparent text-primary hover:bg-primary-500/[0.08] text-button-md max-h-9 w-[200px] text-[16px] underline p-1 font-normal"
          onClick={onApply}
        >
          se cadastre no banco de talentos
        </a>
      </span>
    );
  }, [params?.title, params?.locations, company, hasApplied, onApply]);

  return (
    <div data-testid="home-root">
      <header className="relative">
        <HeaderBanner
          banners={{ sm: company?.banner_mobile, md: company?.banner_mobile, lg: company?.banner }}
          defaultColor={company?.colorCompany}
        />
        <SearchBar className="w-full xl:px-0 px-4 max-w-6xl mx-auto relative bottom-0 z-10" />
      </header>

      <section className="flex flex-col items-stretch px-4 mt-7 mb-7 w-full xl:px-0 max-w-6xl mx-auto">
        <ListVacancies vacancies={vacancies} pagination={pagination} loading={isLoadingListVacancies} />

        {!isLoadingListVacancies && !vacancies.length && (
          <Box className="bg-white text-center flex items-center justify-center p-6 row-span-3">
            <Text variant="subtitle1" className="font-normal text-gray-500">
              {emptyStates}
            </Text>
          </Box>
        )}
      </section>

      <Feedbacks
        data={testimonials || []}
        isLoading={false}
        className="flex flex-col items-center mx-auto pt-10 px-8 pb-4 mb-auto bg-gray-100"
      />

      <BoxCompanyAboutWe className="w-full px-4 xl:px-0 max-w-6xl mx-auto sm:mt-7" company={company} />
      <GallerySection
        className="max-w-[1152px] py-5 px-3 md:px-5 xl:px-0 mx-auto flex flex-col md:flex-row gap-3"
        data={filteredPhotos || []}
        isLoading={false}
      />
      <TalentBank />
    </div>
  );
}

export { Home };
