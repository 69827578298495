const container = ['w-full', 'p-6'].join(' ');

const content = [
  'flex',
  'flex-col',
  'md:flex-row',
  'items-stretch',
  'md:items-end',
  'gap-y-4',
  'md:gap-y-0',
  'gap-x-0',
  'md:gap-x-3',
  'lg:gap-x-5',
].join(' ');

const title = ['font-bold', 'text-[18px]', 'mb-2', 'text-[#212B36]', 'leading-[24px]'].join(' ');
const text = ['text-[14px]', 'text-[#637381]', 'leading-[24px]', 'font-semibold', 'mb-3'].join(' ');
const button = ['w-full', 'md:w-44', 'justify-center'].join(' ');

export { container, content, button, title, text };
