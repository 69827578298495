import React, { useState } from 'react';
import { Button, Spinner, Toast, Checkbox } from '@solides/management-util-ui';

import useStore from 'store/index';
import * as styles from './styles';
import { useRouter } from 'next/router';
import { api } from 'services/api';
import { RecommendationHeader } from 'components/organisms';
import { RecomendationFormType } from './types';
import { TOASTS } from './constants';

export function RecommendationBlock({ recommendation }: RecomendationFormType) {
  const router = useRouter();
  const { company } = useStore(state => state.company);
  const [isConfirm, setIsConfirm] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<'error' | 'success' | null>(null);

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      await api.post(`/recommendations-block/${router.query.token}`);
      setToast('success');

      setTimeout(() => {
        const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
        router.replace(`${origin}?page=1&locations=&title=`);
      }, 1500);
    } catch (error) {
      setToast('error');
      setIsLoading(false);
    }
  };

  return (
    <section data-testid="recommendation-root">
      {isLoading && <Spinner />}

      <Toast
        closeButton
        color={toast}
        isOpen={!!toast}
        onClose={() => setToast(null)}
        title={TOASTS[toast]?.title}
        description={TOASTS[toast]?.description}
      />

      <RecommendationHeader company={company} isExpired={false}>
        <h3 className={styles.title}>
          Deseja não receber mais nenhum pedido de indicação de {recommendation?.applicantName}?
        </h3>
        <p className={styles.text}>
          Atenção, se você confirmar essa ação, o candidato não poderá solicitar um novo pedido de indicação
          para você. Além disso, todos os pedidos de indicação realizados até agora vão ser tratados como
          recusados, tem certeza que deseja fazer isso?
        </p>
        <div>
          <Checkbox
            label="Estou ciente. Desejo rejeitar as indicações solicitadas e não receber mais nenhuma"
            id="confirm"
            checked={isConfirm}
            onClick={() => setIsConfirm(!isConfirm)}
          />
        </div>

        <div className="flex w-full flex-col content-end mt-4">
          <div className="grid grid-cols-1 md:grid-cols-1 gap-y-4 self-end">
            <div>
              <Button
                size="lg"
                color="primary"
                className={`flex-1 justify-center h-16 `}
                data-cy="candidacy-button"
                onClick={onSubmit}
                disabled={!isConfirm || isLoading}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      </RecommendationHeader>
    </section>
  );
}
