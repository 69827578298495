import { Icon } from '@solides/management-util-ui';

type ArrowIconProps = {
  arrow: string;
};

const ArrowIcon = ({ arrow }: ArrowIconProps) => {
  return (
    <div className="animate-pulse">
      <Icon face={arrow} variant="outlined" className="text-gray-400 hidden md:block" />
    </div>
  );
};

export const TestimonialSkeleton = (): JSX.Element => (
  <div
    data-testid="testimonial-loader"
    className="flex flex-col items-center mx-auto max-w-[1152px] gap-8 px-4 sm:flex-row"
  >
    <ArrowIcon arrow="arrow_circle_left" />

    <div className="bg-white rounded-lg shadow-md p-6 w-full h-[250px]">
      <div className="animate-pulse">
        <div className="flex items-center gap-3">
          <div className="bg-gray-400 h-8 w-8 rounded-full mb-4" />
          <div className="bg-gray-400 h-4 w-1/4 mb-2" />
        </div>
        <div className="bg-gray-400 h-4 w-full mb-2" />
        <div className="bg-gray-400 h-4 w-3/4 mb-4" />
        <div className="bg-gray-400 h-4 w-full mb-2" />
        <div className="bg-gray-400 h-4 w-3/4 mb-4" />
        <div className="bg-gray-400 h-4 w-full mb-2" />
      </div>
    </div>
    <div className="bg-white rounded-lg shadow-md p-6 w-full h-[250px] hidden md:block">
      <div className="animate-pulse">
        <div className="flex items-center gap-3">
          <div className="bg-gray-400 h-8 w-8 rounded-full mb-4" />
          <div className="bg-gray-400 h-4 w-1/4 mb-2" />
        </div>
        <div className="bg-gray-400 h-4 w-full mb-2" />
        <div className="bg-gray-400 h-4 w-3/4 mb-4" />
        <div className="bg-gray-400 h-4 w-full mb-2" />
        <div className="bg-gray-400 h-4 w-3/4 mb-4" />
        <div className="bg-gray-400 h-4 w-full mb-2" />
      </div>
    </div>

    <ArrowIcon arrow="arrow_circle_right" />
  </div>
);
