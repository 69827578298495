import { NavBar } from 'components/organisms';
import { Footer } from 'components/molecules';
import { Vacancy } from 'types/vacancy';

interface LayoutProps {
  children: React.ReactNode;
  vacancy?: Vacancy;
}

export const Layout = ({ children, vacancy }: LayoutProps) => (
  <main className="flex bg-gray-200 flex-col min-h-screen">
    <NavBar vacancy={vacancy} />
    <div className="flex-grow gap-y-6">{children}</div>
    <Footer />
  </main>
);
