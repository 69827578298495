import { useQuery } from '@tanstack/react-query';
import { api } from 'services/api';

const getVacancies = async (params: object) => {
  return await api.get('/home/vacancy', { params });
};

export const useVacancies = (params: object, enabled = true, staleTime = 1000 * 60 * 5) => {
  return useQuery({
    queryKey: ['vacancies', params],
    queryFn: () => getVacancies(params),
    enabled,
    staleTime: staleTime, //5 minutes default
  });
};
