import { Text } from '@solides/management-util-ui';
import { Testimonials } from 'components/molecules';
import { Testimonial } from 'types/company';
import { TestimonialSkeleton } from './Skeleton';

type FeedbacksProps = {
  className: string;
  data: Testimonial[];
  isLoading: boolean;
};

export const Feedbacks = ({ className, data, isLoading }: FeedbacksProps) => {
  if (isLoading) return <TestimonialSkeleton />;

  if (data.length === 0) return null;

  return (
    <section className={className}>
      <Text variant="h4" className="max-w-sm sm:max-w-full text-center">
        O que dizem sobre nossa empresa
      </Text>

      <div className="w-full max-w-7xl mt-6">
        <Testimonials testimonials={data} />
      </div>
    </section>
  );
};
